import React, {useEffect} from 'react';
import type {
    GetServerSideProps,
    NextPage,
    InferGetServerSidePropsType,
} from 'next';
import {getNhostSession, useUserData} from '@nhost/nextjs';
import {authProtected} from '@/lib/auth-protected';
import {Link} from '@chakra-ui/next-js';
import {Button, ButtonGroup, Stack, Heading} from '@chakra-ui/react';
import {Layout} from '@/components/layout';
import {LuPhoneCall, LuListChecks} from 'react-icons/lu';

type IndexPageProps = {};

const IndexPage: NextPage<
    InferGetServerSidePropsType<typeof getServerSideProps>
> = () => {
    const user = useUserData();

    return (
        <Layout>
            <Stack spacing={6} p={20}>
                <Heading size="lg">
                    Welches Programm möchtest du starten?
                </Heading>
                <ButtonGroup size="lg" colorScheme="blue">
                    <Button
                        leftIcon={<LuPhoneCall />}
                        as={Link}
                        href="/telefonverzeichnis"
                    >
                        Telefonverzeichnis
                    </Button>
                    <Button
                        leftIcon={<LuListChecks />}
                        as={Link}
                        href="/hausbegehungsprotokoll"
                    >
                        Hausbegehungsprotokoll
                    </Button>
                </ButtonGroup>
            </Stack>
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps<IndexPageProps> = async (
    ctx
) => {
    const nhostSession = await getNhostSession(
        {
            subdomain: process.env.NEXT_PUBLIC_NHOST_SUBDOMAIN,
            region: process.env.NEXT_PUBLIC_NHOST_REGION,
        },
        ctx
    );

    return {
        props: {
            nhostSession,
        },
    };
};

export default authProtected(IndexPage);
