import React, {ReactNode} from 'react';
import {Box, Stack} from '@chakra-ui/react';
import {Navbar} from './navbar';

type LayoutProps = {
    children: ReactNode;
    navbarActions?: ReactNode;
};

export const Layout = ({children, navbarActions}: LayoutProps) => {
    return (
        <Box as="section" overflowY="auto" bg="gray.50" h="100vh">
            <Navbar>{navbarActions}</Navbar>
            <Box
                px={{base: '3', lg: '4'}}
                pt={{base: '4', lg: '6'}}
                pb={{base: '6', lg: '12'}}
            >
                <Stack spacing={{base: '8', lg: '6'}}>{children}</Stack>
            </Box>
        </Box>
    );
};
