import React from 'react';
import {Box} from '@chakra-ui/react';
import NextImage from 'next/image';

export const Logo = () => (
    <Box w="60">
        <NextImage
            alt="logo"
            src="/WEI-Logo-mit-Slogan.png"
            width="768"
            height="177"
        />
    </Box>
);
