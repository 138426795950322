import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Flex,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react';
import {
    LuHelpCircle,
    LuSettings,
    LuChevronDown,
    LuLogOut,
    LuDownload,
} from 'react-icons/lu';
import {Logo} from './logo';
import {useSignOut, useUserData} from '@nhost/nextjs';
import {ReactNode} from 'react';

export const Navbar = ({children}: {children?: ReactNode}) => {
    const userData = useUserData();
    const {signOut} = useSignOut();
    return (
        <Box as="nav" bg="bg.surface" boxShadow="sm">
            <Box px={{base: '3', lg: '4'}} py={{base: '3', lg: '4'}}>
                <Flex justify="space-between">
                    <HStack spacing="4">
                        <Logo />
                    </HStack>

                    <HStack spacing="4">
                        {children}

                        <Menu isLazy>
                            <MenuButton as={Button} variant="text">
                                <Avatar
                                    boxSize="10"
                                    name={userData.displayName}
                                    src={userData.avatarUrl}
                                />
                            </MenuButton>
                            <MenuList>
                                <MenuItem
                                    icon={<LuLogOut />}
                                    onClick={() => signOut()}
                                >
                                    Abmelden
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>
            </Box>
        </Box>
    );
};
